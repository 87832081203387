import isMobile from "../../plugins/is-mobile.js";

var window_h = $(window).height();
$(".c-loading-area").height(window_h);

$(".loading_start").click(function () {
  $(".c-loading-area").addClass("-js-active");
});

if($('.-onloader').length) {
 $('body').css('overflow','hidden')
 $(".c-onloading-area").height(window_h);
 setTimeout(function() {
  $('body').css('overflow','inherit')
  $(".c-onloading-area").fadeOut();
 },700);
}