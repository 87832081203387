var storage_no = "";
var storage_key = "tanosuStorage";
var is_private = "";

window.onload = function () {
  $(".page-favorite-body").css("opacity", "1");
};

function isMobile(breakpoint) {
  return $(window).width() <= breakpoint ? true : false;
}

$(function () {
  initBaseStorage();
  initArticleStorage();
  // setTimeout(function () {
  favBtStorage("c-favorite_article");
  // }, 10000);
  if ($(".favorite_list").length) favListStorage("favorite_list");
});

// 共通：メイン
var initBaseStorage = function () {
  this.hasLocalStorage = true;
  if (typeof localStorage == "undefined") this.hasLocalStorage = false;
};

//記事詳細初回表示制御
var initArticleStorage = function () {
  if (!localStorage.getItem("showed")) {
    $(".c-favorite-notice").addClass("is-active");
  }
  if ($(".-page-article").length) {
    localStorage.setItem("showed", "true");
  }
};

// お気に入りボタン
var favBtStorage = function (target_name) {
  $(document).on("click", function (e) {
    if ($(e.target).closest(".c-favorite_article").length) {
      return false;
    }
  });

  var sList = new kpStorageList(target_name);

  if (sList.hasLocalStorage) {
    //お気に入りボタン
    var btTarget = $(document).on("click", `.${target_name}`, function (e) {
      // console.log($(this).attr('href'));
      var limit = "";
      if (localStorage.getItem(target_name)) {
        var limit = localStorage.getItem(target_name).split(",");
        var exclude = limit.pop();
      }

      // console.log(limit);

      if ($(this).attr("href").length) {
        e.preventDefault();

        var pageArticleID = $(this).attr("href");

        if (sList.storageCheckId(pageArticleID)) {
          sList.storageRemoveList(pageArticleID);
          $(this).removeClass("anim").removeClass("enable");
          $(".-page-article").removeClass("enable");
          $(".c-button__text").text("この記事をお気に入りに保存する");
        } else {
          if (limit.length == 60) {
            alert("お気に入り登録数は60件までです");
            return false;
          } else {
            sList.storageAddList(pageArticleID);
            $(this).addClass("anim").addClass("enable");
            $(".-page-article").addClass("enable");
            $(".c-button__text").text("この記事をお気に入りから解除する");
          }
        }
      }
    });

    $("." + target_name).each(function (i, o) {
      var pageArticleID = $(o).attr("href");
      if (sList.storageCheckId(pageArticleID)) {
        $(o).addClass("enable");
        $(".-page-article").addClass("enable");
        $(".c-button__text").text("この記事をお気に入りから解除する");
      } else {
        $(".c-button__text").text("この記事をお気に入りに保存する");
      }
    });
  } else {
    $("." + target_name).hide();
  }
  //	}
};
// お気に入り一覧
var favListStorage = function (target_name) {
  var sList = new kpStorageList(target_name);
  if (sList.hasLocalStorage) {
    sList.storageViewList(target_name);
  } else {
    $("." + target_name).hide();
  }
  //	}
};

// 一覧：各処理
var kpStorageList = function (target_name) {
  try {
    // console.log("ローカルストレージtrue");
    this.hasLocalStorage = true;

    if (typeof localStorage == "undefined") this.hasLocalStorage = false;

    console.log(this.hasLocalStorage);

    // IDリスト取得
    this.storageGetList = function () {
      return this.loadArray(target_name);
    };

    // 該当IDの存在チェック
    this.storageCheckId = function (target_id) {
      // console.log(target_id);
      if (this.storageGetList().indexOf(String(target_id)) != -1) return true;
      else return false;
    };

    // リストからID追加
    this.storageAddList = function (target_id) {
      if (!this.storageCheckId(target_id)) {
        var list = this.storageGetList();
        if (list.length) list.unshift(target_id);
        else list[0] = target_id;
        localStorage.setItem(target_name, list);
        // this.storageRegistDb();
      }
    };

    // リストからID削除
    this.storageRemoveList = function (target_id) {
      var list = this.storageGetList();
      if (list.length) {
        var i = list.indexOf(String(target_id));
        list.splice(i, 1);
        localStorage.setItem(target_name, list);
        // this.storageRegistDb();
      }
    };
    //----------------------------------------------------------
    //保存状態をArrayで取得
    this.loadArray = function (key) {
      var data = localStorage.getItem(key);
      if (data == null) return new Array();
      else return data.split(",");
    };
  } catch (err) {
    // localStorage may be disabled, skip
  }
};
