import addEventListenerMultiType from "./multiEventLister.js";

class TransactionLink {
  constructor() {
    this.targets = document.querySelectorAll(".js-transaction-link");
  }

  init() {
    this.attachEvent();
  }

  attachEvent() {
    for (let target of this.targets) {
      target.addEventListener("click", () => {
        console.log("トランザクション");
        dataLayer.push({
          transactionId: new Date().getTime(),
          transactionTotal: target.dataset.price,
          transactionProducts: [
            {
              sku: target.dataset.sku,
              name: target.dataset.name,
              category: target.dataset.category,
              price: target.dataset.price,
              quantity: 1,
            },
          ],
        });
      });
    }
  }
}

const transactionLink = new TransactionLink();
transactionLink.init();
